import React, { useContext, useState, useEffect } from 'react';
import { CreateButton } from 'react-admin';
import UserContext from '../Utils/UserContext';
import { makeStyles } from '@material-ui/core/styles';
import CheckPermission from '../Utils/CheckPermission';
import ParentOrgField from '../Components/ParentOrgField';
import { Datagrid, List, TextField, DateField, SearchInput } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    textAlign: 'center'
  }
}));

const filters = [<SearchInput source='name' alwaysOn />];

const OrganizationList = (props) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [createPermission, setCreatePermission] = useState(false);

  useEffect(() => {
    setCreatePermission(CheckPermission(user, 'Organization_Create'));
  }, [user]);

  return (
    <List
      title='Organizations'
      {...props}
      perPage={10}
      filters={filters}
      bulkActionButtons={false}
      actions={createPermission ? <CreateButton /> : null}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid optimized rowClick='edit'>
        <TextField label='ID' source='id' className={classes.text} />
        <TextField label='Org Name' source='name' className={classes.text} />
        <ParentOrgField />
        <DateField label='Last Updated' source='modifiedDate' />
        <TextField
          label='Created By'
          source='createdBy.name'
          className={classes.text}
        />
        <DateField label='Created Date' source='createdDate' />
      </Datagrid>
    </List>
  );
};

export default OrganizationList;
