import React, { useState, useEffect } from 'react';
import {
  Create,
  required,
  TextInput,
  SimpleForm,
  SelectInput,
  Loading,
  Error,
  email,
  useNotify,
  useRefresh,
  useRedirect,
  ImageField,
  ImageInput,
  SelectArrayInput
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import { httpClient } from '../DataProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCard-root': {
      maxWidth: '500px'
    },
    '& .MuiFormControlLabel-root': {
      marginTop: 20
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .previews': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        borderRadius: 20
      }
    }
  }
}));

const UsersCreate = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [error, setError] = useState(false);
  const [loadingOrgs, setLoadingOrgs] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [roles, setRoles] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);

  useEffect(() => {
    setRoles([]);
    setLoadingRoles(true);
    httpClient(
      `${process.env.REACT_APP_BASE_URL}/Admin/GetRolesByType?type=create`
    )
      .then(({ json }) => {
        json.response.forEach((role) => {
          let obj = {
            id: role.id,
            name: role.description
          };
          setRoles((state) => [...state, obj]);
        });
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoadingRoles(false);
      });
  }, []);

  useEffect(() => {
    setOrganizations([]);
    setLoadingOrgs(true);
    httpClient(
      `${process.env.REACT_APP_BASE_URL}/Organizations/GetUserOrganizations`
    )
      .then(({ json }) => {
        json.response.forEach((org) => {
          let obj = {
            id: org.id,
            name: org.name
          };
          setOrganizations((state) => [...state, obj]);
        });
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoadingOrgs(false);
      });
  }, []);

  const formatNumber = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (value.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  const organizationSelected = (event) => {
    let selected = event.target.value;
    let orgs = organizations.filter((org) => selected.includes(org.id));
    setSelectedOrganizations(orgs);
  };

  const onSuccess = (res) => {
    notify(`User created successfully.`, { type: 'success' });
    redirect('/Admin/Users');
    refresh();
  };

  if (loadingOrgs || loadingRoles)
    return (
      <Loading loadingSecondary='The page is loading. Please wait a moment...' />
    );
  if (error) return <Error />;

  return (
    <Create title='Create User' {...props} onSuccess={onSuccess}>
      <SimpleForm className={classes.root} save={props.save} redirect='list'>
        <Box
          display={{ md: 'block', lg: 'flex' }}
          style={{ width: '100%' }}
          mb='4em'
        >
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant='h6' gutterBottom>
              Create User
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='First Name'
                  source='firstName'
                  validate={requiredValidate}
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='Last Name'
                  source='lastName'
                  validate={requiredValidate}
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='Display Name'
                  source='displayName'
                  validate={requiredValidate}
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='Email Address'
                  source='email'
                  validate={requiredValidate}
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='Phone #'
                  source='phoneNumber'
                  fullWidth
                  format={formatNumber}
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='Patient ID'
                  source='patientId'
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectArrayInput
                  label='Organizations'
                  source='userOrganizations'
                  choices={organizations}
                  helperText={false}
                  onChange={organizationSelected}
                  validate={requiredValidate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectInput
                  label='Default Organization'
                  source='defaultOrganization'
                  choices={selectedOrganizations}
                  helperText={false}
                  validate={requiredValidate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectArrayInput
                  label='Roles'
                  source='roles'
                  choices={roles}
                  helperText={false}
                  validate={requiredValidate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label='Report Email Destination'
                  source='reportEmail'
                  validate={email()}
                  fullWidth
                  helperText={false}
                />
              </Grid>
            </Grid>
          </Box>
          <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
            <Card>
              <CardContent>
                <ImageInput
                  source='avatar'
                  label='Avatar'
                  accept='image/*'
                  placeholder='Click to upload or change the logo'
                >
                  <ImageField source='src' title='title' />
                </ImageInput>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

export default UsersCreate;
