import * as React from 'react';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { lightTheme } from './theme';

const CustomLayout = (props) => {
  return <Layout {...props} appBar={AppBar} menu={Menu} theme={lightTheme} />;
};

export default CustomLayout;
