import React, { useState, useEffect } from 'react';
import Users from './Users';
import routes from './routes';
import Layout from './Layout';
import Login from './Auth/Login';
import AuthProvider from './AuthProvider';
import DataProvider from './DataProvider';
import { httpClient } from './DataProvider';
import Organizations from './Organizations';
import { Loading, Error } from 'react-admin';
import { Admin, Resource } from 'react-admin';
import UserContext from './Utils/UserContext';
import CheckPermission from './Utils/CheckPermission';

const resources = [
  { name: 'Admin/Users', value: Users },
  { name: 'Organizations', value: Organizations }
];

const App = () => {
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (!token) return;
    setLoading(true);
    httpClient(`${process.env.REACT_APP_BASE_URL}/Admin/GetLoggedInUserInfo`)
      .then(({ json }) => {
        setLoading(false);
        setUser(json.response);
      })
      .catch((error) => {
        localStorage.removeItem('token');
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (CheckPermission(user, 'Organization_Read')) {
      resources.splice(1, 0, { name: 'Organizations', value: Organizations });
    }
  }, [user]);

  if (loading)
    return (
      <Loading
        loadingPrimary='Loading'
        loadingSecondary='The page is loading. Please wait a moment...'
      />
    );
  if (error) return <Error />;

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Admin
        layout={Layout}
        loginPage={Login}
        customRoutes={routes}
        dataProvider={DataProvider}
        authProvider={AuthProvider}
      >
        {resources.map((resource, index) => (
          <Resource key={index} name={resource.name} {...resource.value} />
        ))}
        {/* <Resource name='Organizations' {...Organizations} />
        <Resource name='Admin/Users' {...Users} />
        <Resource name='Common/GetRoles' {...Roles} /> */}
      </Admin>
    </UserContext.Provider>
  );
};

export default App;
