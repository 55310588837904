import simpleRestProvider from 'ra-data-json-server';
import { fetchUtils } from 'react-admin';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = simpleRestProvider(BASE_URL, httpClient);

const myDataProvider = {
  ...dataProvider,
  getList: async (resource, params) => {
    let url = `${BASE_URL}/${resource}`;
    switch (resource) {
      default:
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const sort = JSON.stringify({ [field]: order });
        const range = JSON.stringify({
          [(page - 1) * perPage]: page * perPage - 1
        });
        const filter = JSON.stringify(params.filter);
        url = `${url}?sort=${sort}&range=${range}&filter=${filter}`;

        return httpClient(url).then(({ json, headers }) => ({
          data: json.response,
          total: parseInt(
            headers.get('content-range').split('/').pop(),
            perPage
          )
        }));
    }
  },

  create: async (resource, params) => {
    let response = null;

    switch (resource) {
      case 'Organizations':
        params.data.parentOrganization = params.data.isParent
          ? null
          : params.data.parentOrganization;
        params.data.isParent = params.data.isParent
          ? params.data.isParent
          : false;

        if (!params.data.logo) {
          const { json } = await httpClient(`${BASE_URL}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data)
          });
          response = json;
        } else {
          const base64Image = await convertFileToBase64(params.data.logo);
          params.data.logo = base64Image;
          const { json } = await httpClient(`${BASE_URL}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data)
          });
          response = json;
        }
        if (response.responseCode === 200) {
          return { data: response.response };
        } else {
          throw new Error(response.responseMessage);
        }

      case 'Admin/Users':
        params.data = prepareUserPostData(params.data);
        delete params.data.userOrganizations;
        delete params.data.defaultOrganization;

        if (!params.data.avatar) {
          const { json } = await httpClient(`${BASE_URL}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data)
          });
          response = json;
        } else {
          const base64Image = await convertFileToBase64(params.data.avatar);
          params.data.avatar = base64Image;
          const { json } = await httpClient(`${BASE_URL}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data)
          });
          response = json;
        }
        if (response.responseCode === 200) {
          return { data: response.response };
        } else {
          throw new Error(response.responseMessage);
        }

      default:
        response = await dataProvider.create(resource, params);
        return { data: response.data.response };
    }
  },

  update: async (resource, params) => {
    let response = null;

    switch (resource) {
      case 'Organizations':
        params.data.parentOrganization = params.data.isParent
          ? null
          : params.data.parentOrganization;

        if (!params.data.logo) {
          response = await dataProvider.update(resource, params);
        } else {
          if (
            params.data.logo.rawFile &&
            params.data.logo.rawFile instanceof File
          ) {
            await convertFileToBase64(params.data.logo).then(
              async (base64Image) =>
                (response = await dataProvider.update(resource, {
                  ...params,
                  data: {
                    ...params.data,
                    logo: base64Image
                  }
                }))
            );
          } else {
            response = await dataProvider.update(resource, {
              ...params,
              data: {
                ...params.data,
                logo: params.data.logo.src
              }
            });
          }
        }
        if (response.data.responseCode === 200) {
          return { data: response.data.response };
        } else {
          throw new Error(response.data.responseMessage);
        }

      case 'Admin/Users':
        params.data = prepareUserPostData(params.data);
        if (!params.data.avatar) {
          response = await dataProvider.update(resource, params);
        } else {
          if (
            params.data.avatar.rawFile &&
            params.data.avatar.rawFile instanceof File
          ) {
            await convertFileToBase64(params.data.avatar).then(
              async (base64Image) =>
                (response = await dataProvider.update(resource, {
                  ...params,
                  data: {
                    ...params.data,
                    avatar: base64Image
                  }
                }))
            );
          } else {
            response = await dataProvider.update(resource, {
              ...params,
              data: {
                ...params.data,
                avatar: params.data.avatar.src
              }
            });
          }
        }
        if (response.data.responseCode === 200) {
          return { data: response.data.response };
        } else {
          throw new Error(response.data.responseMessage);
        }

      default:
        response = await dataProvider.update(resource, params);
        return { data: response.data.response };
    }
  },

  getOne: async (resource, params) => {
    const res = await dataProvider.getOne(resource, params);
    if (res.data.responseCode !== 200)
      throw new Error(res.data.responseMessage);

    const response = { data: res.data.response };

    switch (resource) {
      case 'Organizations':
        if (response.data.logo) {
          response.data.logo = {
            name: 'logo',
            src: response.data.logo
          };
        }
        return response;

      case 'Admin/Users':
        if (response.data.avatar) {
          response.data.avatar = {
            name: 'avatar',
            src: response.data.avatar
          };
        }
        if (response.data && response.data.roles) {
          let roles = [];
          response.data.roles.forEach((role) => {
            roles.push(role.id);
          });
          response.data.roles = roles;
        }

        if (response.data && response.data.organizations) {
          let userOrganizations = [];
          let defaultOrganization = null;
          response.data.organizations.forEach((organization) => {
            if (organization.isDefault) defaultOrganization = organization.id;
            userOrganizations.push(organization.id);
          });
          response.data.userOrganizations = userOrganizations;
          response.data.defaultOrganization = defaultOrganization;
        }
        return response;

      default:
        return response;
    }
  },

  delete: async (resource, params) => {
    console.log(params);
    let response = null;
    switch (resource) {
      case 'Organizations':
        response = await dataProvider.delete(resource, params);
        if (response.data.responseCode === 200) {
          return { data: { id: params.id, ...response.data.response } };
        } else {
          throw new Error(response.data.responseMessage);
        }

      case 'Admin/Users':
        response = await dataProvider.delete(resource, params);
        console.log(response);
        if (response.data.responseCode === 200) {
          return { data: { id: params.id, ...response.data.response } };
        } else {
          throw new Error(response.data.responseMessage);
        }

      default:
        return dataProvider.delete(resource, params);
    }
  },

  deleteMany: async (resource, params) => {
    switch (resource) {
      case 'Organizations':
        const url = `${BASE_URL}/${resource}/DeleteMultiple`;

        return httpClient(url, {
          method: 'DELETE',
          body: JSON.stringify(params.ids)
        }).then(({ json }) => ({ data: json.response }));

      default:
        return dataProvider.deleteMany(resource, params);
    }
  }
};

// Functions

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

const prepareUserPostData = (data) => {
  let organizations = [];

  if (data.phoneNumber) {
    data.phoneNumber = data.phoneNumber.match(/\d/g).join('');
  }

  if (data.userOrganizations) {
    data.userOrganizations.forEach((organization) => {
      organizations.push({
        id: organization,
        isDefault: organization === data.defaultOrganization ? true : false
      });
    });
  }

  data.organizations = organizations;

  delete data.userOrganizations;
  delete data.defaultOrganization;

  return data;
};

export default myDataProvider;
