import React, { useContext } from 'react';
import { UserMenu, MenuItemLink } from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';
import UserContext from '../Utils/UserContext';

const useStyles = makeStyles({
  item: {
    color: 'rgba(0, 0, 0, 0.54)',
    border: 'none'
  }
});

const CustomUserMenu = (props) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);

  return (
    <UserMenu {...props}>
      <MenuItemLink
        to={{
          pathname: `/Admin/Users/${user.id}`
        }}
        className={classes.item}
        primaryText='Profile'
        leftIcon={<PersonIcon color='primary' />}
      />
    </UserMenu>
  );
};

export default CustomUserMenu;
