import React from 'react';
import RoleField from '../Components/RoleField';
import { makeStyles } from '@material-ui/core/styles';
import FullNameField from '../Components/FullNameField';
import { Datagrid, List, TextField, DateField, SearchInput } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  text: {
    whiteSpace: 'nowrap',
    textAlign: 'center'
  }
}));

const filters = [<SearchInput source='name' alwaysOn />];

const UsersList = (props) => {
  const classes = useStyles();

  return (
    <List
      title='Users'
      {...props}
      perPage={10}
      filters={filters}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid optimized rowClick='edit'>
        <TextField label='ID' source='id' className={classes.text} />
        <FullNameField className={classes.text} />
        <TextField label='Email' source='email' className={classes.text} />
        <RoleField className={classes.text} />
        <TextField
          label='Organization name'
          source='organization'
          className={classes.text}
        />
        <TextField
          label='Status'
          source='status'
          className={classes.text}
        />
        <TextField
          label='Created By'
          source='createdBy.name'
          className={classes.text}
        />
        <DateField label='Created On' source='createdDate' />
      </Datagrid>
    </List>
  );
};

export default UsersList;
