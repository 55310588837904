import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../Utils/UserContext';
import CheckPermission from '../Utils/CheckPermission';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 25,
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 600
  }
}));

const SuperAdminDashboard = () => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [viewPermission, setViewPermission] = useState(false);

  useEffect(() => {
    setViewPermission(CheckPermission(user, 'Super_Admin_Dashboard'));
  }, [user]);

  if (!viewPermission)
    return <div>You do not have the permission to access this page.</div>;

  return <div className={classes.root}>Super Admin Dashboard</div>;
};

export default SuperAdminDashboard;
