import React from 'react';
import { lightTheme } from '../Layout/theme';
import Checkbox from '@mui/material/Checkbox';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: 10
  },
  head: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderBottom: `2px solid ${theme.palette.primary.main}`
  }
}));

const CRUDInput = (props) => {
  const classes = useStyles();
  const { role, handleToggle } = props;

  const toggleCheckbox = (roleId, permissionId, event) => {
    handleToggle(roleId, permissionId, event.target.checked);
  };

  return (
    <>
      <Grid item xs={12} className={classes.input}>
        <Typography variant='h5' className={classes.head}>
          {role.roleDescription}
        </Typography>
      </Grid>
      {role.permissions.map((permission) => (
        <React.Fragment key={permission.permissionId}>
          <Grid item xs={7} md={4}>
            <Typography variant='body2' gutterBottom className={classes.input}>
              {permission.permissionDescription}
            </Typography>
          </Grid>
          <Grid item xs={5} md={2}>
            <Checkbox
              checked={permission.havePermission}
              onClick={(event) =>
                toggleCheckbox(role.roleId, permission.permissionId, event)
              }
            />
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};

const CRUDInputWithTheme = (props) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <CRUDInput {...props} />
  </ThemeProvider>
);

export default CRUDInputWithTheme;
