import UsersList from './UsersList';
import UsersEdit from './UsersEdit';
import UsersCreate from './UsersCreate';
import UsersIcon from '@mui/icons-material/Group';

const resource = {
  list: UsersList,
  create: UsersCreate,
  edit: UsersEdit,
  icon: UsersIcon,
  options: { label: 'Users' }
};

export default resource;
