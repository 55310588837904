import React, { useContext, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItemLink } from 'react-admin';
import Dashboard from '@mui/icons-material/Dashboard';
import LockOpen from '@mui/icons-material/LockOpen';
import UserContext from '../Utils/UserContext';
import CheckPermission from '../Utils/CheckPermission';
import users from '../Users';
import organizations from '../Organizations';

const Menu = ({ dense = false }) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme);
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [readOrgsPermission, setReadOrgsPermission] = useState(false);
  const [privilegesPermission, setPrivilegesPermission] = useState(false);
  const [superAdminDashboardPermission, setSuperAdminDashboardPermission] =
    useState(false);
  const [orgAdminDashboardPermission, setOrgAdminDashboardPermission] =
    useState(false);
  const [userAdminDashboardPermission, setUserAdminDashboardPermission] =
    useState(false);

  useEffect(() => {
    if (user.roles && user.roles.length && user.roles[0].id === 1) {
      setPrivilegesPermission(true);
    }
    setReadOrgsPermission(CheckPermission(user, 'Organization_Read'));
    setSuperAdminDashboardPermission(
      CheckPermission(user, 'Super_Admin_Dashboard')
    );
    setOrgAdminDashboardPermission(
      CheckPermission(user, 'Org_Admin_Dashboard')
    );
    setUserAdminDashboardPermission(
      CheckPermission(user, 'User_Admin_Dashboard')
    );
  }, [user]);

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open
      })}
    >
      {/* <DashboardMenuItem /> */}
      <MenuItemLink
        to={{
          pathname: '/Admin/Users',
          state: { _scrollToTop: true }
        }}
        primaryText='Users'
        leftIcon={<users.icon />}
      />
      {readOrgsPermission && (
        <MenuItemLink
          to={{
            pathname: '/Organizations',
            state: { _scrollToTop: true }
          }}
          primaryText='Organizations'
          leftIcon={<organizations.icon />}
        />
      )}
      {privilegesPermission && (
        <MenuItemLink
          to={{
            pathname: '/Permissions',
            state: { _scrollToTop: true }
          }}
          primaryText='User privileges'
          leftIcon={<LockOpen />}
        />
      )}
      {superAdminDashboardPermission && (
        <MenuItemLink
          to={{
            pathname: '/Super-Admin-Dashboard',
            state: { _scrollToTop: true }
          }}
          primaryText='Dashboard'
          leftIcon={<Dashboard />}
        />
      )}
      {orgAdminDashboardPermission && (
        <MenuItemLink
          to={{
            pathname: '/Org-Admin-Dashboard',
            state: { _scrollToTop: true }
          }}
          primaryText='Org Admin Dashboard'
          leftIcon={<Dashboard />}
        />
      )}
      {userAdminDashboardPermission && (
        <MenuItemLink
          to={{
            pathname: '/User-Admin-Dashboard',
            state: { _scrollToTop: true }
          }}
          primaryText='User Admin Dashboard'
          leftIcon={<Dashboard />}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  open: {
    width: 240
  },
  closed: {
    width: 55
  }
}));

export default Menu;
