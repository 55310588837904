import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap'
  }
}));

const FullNameField = ({ record }) => {
  const classes = useStyles();

  if (!record.firstName && !record.lastName)
    return <div className={classes.root}>{record.displayName}</div>;

  return (
    <div className={classes.root}>
      {record.firstName + ' ' + record.lastName}
    </div>
  );
};

FullNameField.defaultProps = {
  source: 'firstName',
  addLabel: true,
  label: 'Name'
};

export default FullNameField;
