import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import { Link } from 'react-admin';
import { lightTheme } from '../Layout/theme';
import CheckIcon from '@material-ui/icons/Check';
import { ThemeProvider } from '@material-ui/styles';
import { Field, withTypes } from 'react-final-form';
import { Notification, useNotify } from 'react-admin';
import { createTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: '#813767',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    width: 375,
    marginTop: '6em'
  },
  logo: {
    marginTop: '0.5em',
    width: 150
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em'
  },
  actions: {
    padding: '1em'
  },
  oval1: {
    top: 0,
    right: 0,
    width: '25%',
    position: 'absolute'
  },
  oval2: {
    left: 0,
    bottom: 0,
    width: '50%',
    position: 'absolute'
  },
  footer: {
    marginTop: '1em',
    textAlign: 'center',
    '& span': {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  icon: {
    background: '#46a946'
  },
  emailSent: {
    padding: '1em',
    textAlign: 'center',
    marginBottom: '1em'
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const BASE_URL = process.env.REACT_APP_BASE_URL;

const { Form } = withTypes();

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const classes = useStyles();
  const notify = useNotify();

  const handleSubmit = ({ email }) => {
    setLoading(true);
    const request = new Request(
      `${BASE_URL}/Account/ResetPassword?email=${email}`,
      {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' })
      }
    );
    fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ responseCode, responseMessage }) => {
        if (responseCode === 200) {
          setResetSuccessful(true);
          notify('An Email has been sent to the email address', {
            type: 'success'
          });
        } else {
          notify(responseMessage, { type: 'warning' });
        }
      })
      .catch((e) => {
        notify('An error occurred', { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validate = (values) => {
    const errors = {};
    const re = /\S+@\S+\.\S+/;
    if (!re.test(values.email)) {
      errors.email = 'Email is not valid';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <img
                  src='/images/mybeam-logo.png'
                  alt='logo'
                  className={classes.logo}
                />
              </div>
              <Typography variant='h5' align='center'>
                Admin
              </Typography>
              {resetSuccessful ? (
                <div className={classes.emailSent}>
                  <div className={classes.avatar}>
                    <Avatar className={classes.icon}>
                      <CheckIcon />
                    </Avatar>
                  </div>
                  <Typography variant='subtitle1' align='center'>
                    An email has been sent to the email that you provided if it
                    exists in the database
                  </Typography>
                  <div className={classes.footer}>
                    <Link to={`/login`}>
                      <span>Go Back to Login</span>
                    </Link>
                  </div>
                </div>
              ) : (
                <>
                  <div className={classes.form}>
                    <Typography
                      variant='subtitle1'
                      align='center'
                      className={classes.input}
                    >
                      Please provide the email that you signed up with.
                    </Typography>
                    <div className={classes.input}>
                      <Field
                        name='email'
                        component={renderInput}
                        label='Email'
                        disabled={loading}
                      />
                    </div>
                    <div className={classes.footer}>
                      <Link to={`/Login`}>
                        <span>Go Back to Login</span>
                      </Link>
                    </div>
                  </div>
                  <CardActions className={classes.actions}>
                    <Button
                      variant='contained'
                      type='submit'
                      color='primary'
                      disabled={loading}
                      fullWidth
                    >
                      {loading && <CircularProgress size={25} thickness={2} />}
                      Reset Password
                    </Button>
                  </CardActions>
                </>
              )}
            </Card>
            <Notification />
            <img src='/images/oval1.png' alt='img' className={classes.oval1} />
            <img src='/images/oval2.png' alt='img' className={classes.oval2} />
          </div>
        </form>
      )}
    />
  );
};

ForgotPassword.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string
};

const FrogotPasswordWithTheme = (props) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <ForgotPassword {...props} />
  </ThemeProvider>
);

export default FrogotPasswordWithTheme;
