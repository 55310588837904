import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap'
  }
}));

const RoleField = ({ record }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {record.roles.length ? record.roles[0].name : '-'}
    </div>
  );
};

RoleField.defaultProps = {
  source: 'roles',
  addLabel: true,
  label: 'Role'
};

export default RoleField;
