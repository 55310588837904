import React, { useState, useEffect, useContext } from 'react';
import {
  Edit,
  Link,
  required,
  TextInput,
  ImageInput,
  ImageField,
  useGetList,
  BooleanInput,
  FormDataConsumer,
  SelectInput,
  Loading,
  Error,
  useNotify,
  useRefresh,
  useRedirect,
  SimpleForm,
  Toolbar,
  SaveButton,
  DeleteButton
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';
import History from '../Components/History';
import { Person } from '@material-ui/icons';
import UserContext from '../Utils/UserContext';
import CheckPermission from '../Utils/CheckPermission';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCard-root': {
      maxWidth: '500px'
    },
    '& .MuiFormControlLabel-root': {
      marginTop: 20
    },
    '& .MuiFormControl-root': {
      minWidth: 250
    },
    '& .previews': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        borderRadius: 20
      }
    }
  },
  icon: {
    marginRight: 5
  }
}));

const useToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const CustomToolbar = (props) => {
  const { user } = useContext(UserContext);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);

  useEffect(() => {
    setUpdatePermission(CheckPermission(user, 'Organization_Update'));
    setDeletePermission(CheckPermission(user, 'Organization_Delete'));
  }, [user]);

  return (
    <Toolbar {...props} classes={useToolbarStyles()}>
      {updatePermission && <SaveButton />}
      {deletePermission && <DeleteButton undoable={false} />}
    </Toolbar>
  );
};

const OrganizationsEdit = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { data, loading, error } = useGetList('Organizations');
  const [parentOrganizations, setParentOrganizations] = useState([]);

  useEffect(() => {
    setParentOrganizations([]);
    let keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key].isParent && data[key].id !== parseInt(props.id)) {
        let org = {
          id: data[key].id,
          name: data[key].name
        };
        setParentOrganizations((state) => [...state, org]);
      }
    });
  }, [data, props]);

  const onSuccess = (res) => {
    notify(`Organization updated successfully.`, { type: 'success' });
    redirect('/Organizations');
    refresh();
  };

  if (loading)
    return (
      <Loading loadingSecondary='The page is loading. Please wait a moment...' />
    );
  if (error) return <Error />;

  return (
    <Edit
      title='Edit Organization'
      {...props}
      mutationMode='pessimistic'
      onSuccess={onSuccess}
    >
      <SimpleForm
        className={classes.root}
        save={props.save}
        validate={validateOrgUpdation}
        toolbar={<CustomToolbar />}
      >
        <Box
          display={{ md: 'block', lg: 'flex' }}
          style={{ width: '100%' }}
          mb='4em'
        >
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant='h6' gutterBottom>
              Create Organization
            </Typography>
            <TextInput
              label='Name'
              source='name'
              validate={requiredValidate}
              fullWidth
              helperText={false}
            />
            <TextInput
              label='Description'
              source='description'
              validate={requiredValidate}
              fullWidth
              helperText={false}
            />
            <BooleanInput label='Parent Organization' source='isParent' />
            <FormDataConsumer>
              {({ formData }) =>
                !formData.isParent && (
                  <>
                    <Typography>Parent Organization</Typography>
                    <SelectInput
                      name='parentOrganization'
                      label='Parent Organization'
                      source='parentOrganization'
                      choices={parentOrganizations}
                    />
                  </>
                )
              }
            </FormDataConsumer>
          </Box>
          <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
            <Card>
              <CardContent>
                <ImageInput
                  source='logo'
                  label='Logo'
                  accept='image/*'
                  placeholder='Click to upload or change the logo'
                >
                  <ImageField source='src' title='title' />
                </ImageInput>
              </CardContent>
            </Card>

            <Box mt='2em'></Box>

            <Card>
              <CardContent>
                <Typography variant='h6' gutterBottom>
                  Admins
                </Typography>
                <Box mt='1em'>
                  <Box display='flex' justifyContent='space-between'>
                    <Box display='flex' alignItems='center'>
                      <Person
                        fontSize='small'
                        color='disabled'
                        className={classes.icon}
                      />
                      <Typography>Org Admin</Typography>
                    </Box>
                    <FormDataConsumer>
                      {({ formData }) => (
                        <>
                          {formData.creator ? (
                            <Link to={`/users/${formData.creator.id}`}>
                              Creator Name
                            </Link>
                          ) : (
                            'N/A'
                          )}
                        </>
                      )}
                    </FormDataConsumer>
                  </Box>
                </Box>
              </CardContent>
            </Card>

            <Box mt='2em'></Box>
            <History />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

const requiredValidate = [required()];

const validateOrgUpdation = (values) => {
  const errors = {};
  if (!values.isParent && !values.parentOrganization) {
    errors.parentOrganization = 'Parent Organiaztion is required';
  }
  return errors;
};

export default OrganizationsEdit;
