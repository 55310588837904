import * as React from 'react';
import { AppBar } from 'react-admin';
import UserMenu from './UserMenu';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  },
  logo: {
    height: 35
  }
});

const CustomAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props} elevation={1} userMenu={<UserMenu />}>
      <Typography
        variant='h6'
        color='inherit'
        className={classes.title}
        id='react-admin-title'
      />
      <img src='/images/mybeam-logo.png' alt='logo' className={classes.logo} />
      <Box ml='1em'>
        <Typography variant='h5' align='center'>
          Admin
        </Typography>
      </Box>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
