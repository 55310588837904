import React, { useState, useEffect, useContext } from 'react';
import {
  Edit,
  required,
  TextInput,
  SimpleForm,
  SelectInput,
  Loading,
  Error,
  email,
  Toolbar,
  useNotify,
  ImageField,
  ImageInput,
  useRefresh,
  useRedirect,
  SaveButton,
  DeleteButton,
  SelectArrayInput,
  FormDataConsumer
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Grid,
  Button
} from '@material-ui/core';
import History from '../Components/History';
import { httpClient } from '../DataProvider';
import UserContext from '../Utils/UserContext';
import { useFormState } from 'react-final-form';
import EmailIcon from '@mui/icons-material/Email';
import CheckPermission from '../Utils/CheckPermission';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCard-root': {
      maxWidth: '500px'
    },
    '& .MuiFormControlLabel-root': {
      marginTop: 20
    },
    '& .MuiFormControl-root': {
      width: '100%'
    },
    '& .previews': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        borderRadius: 20
      }
    }
  },
  btnText: {
    marginLeft: 5
  }
}));

const DefaultOrgInput = (props) => {
  const { values } = useFormState();
  return (
    <SelectInput
      {...props}
      name='defaultOrganization'
      validate={requiredValidate}
      choices={props.organizations.filter((org) =>
        values.userOrganizations.includes(org.id)
      )}
    />
  );
};

const useToolbarStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const updatePermissions = {
  2: 'Organization_Admin_Update',
  3: 'User_Admin_Update',
  4: 'User_Update'
};

const deletePermissions = {
  2: 'Organization_Admin_Delete',
  3: 'User_Admin_Delete',
  4: 'User_Delete'
};

const CustomToolbar = (props) => {
  const { user } = useContext(UserContext);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);

  useEffect(() => {
    if (!user.roles.length || !props.record.roles.length) return;
    let userRole = user.roles[0].id;
    let role = props.record.roles[0].id || props.record.roles[0];
    if (role === 1 && userRole === 1) {
      setUpdatePermission(true);
      setDeletePermission(true);
    } else {
      setUpdatePermission(CheckPermission(user, updatePermissions[role]));
      setDeletePermission(CheckPermission(user, deletePermissions[role]));
    }
  }, [user, props]);

  return (
    <Toolbar {...props} classes={useToolbarStyles()}>
      {updatePermission && <SaveButton />}
      {deletePermission && <DeleteButton undoable={false} />}
    </Toolbar>
  );
};

const UsersEdit = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [error, setError] = useState(false);
  const [loadingOrgs, setLoadingOrgs] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [sending, setSending] = useState(false);
  const [roles, setRoles] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    setRoles([]);
    setLoadingRoles(true);
    httpClient(`${process.env.REACT_APP_BASE_URL}/Admin/GetRolesByType`)
      .then(({ json }) => {
        json.response.forEach((role) => {
          let obj = {
            id: role.id,
            name: role.name
          };
          setRoles((state) => [...state, obj]);
        });
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoadingRoles(false);
      });
  }, []);

  useEffect(() => {
    setOrganizations([]);
    setLoadingOrgs(true);
    httpClient(
      `${process.env.REACT_APP_BASE_URL}/Organizations/GetUserOrganizations`
    )
      .then(({ json }) => {
        json.response.forEach((org) => {
          let obj = {
            id: org.id,
            name: org.name
          };
          setOrganizations((state) => [...state, obj]);
        });
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoadingOrgs(false);
      });
  }, []);

  const formatNumber = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (value.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  const resendAccessCode = () => {
    setSending(true);
    let userId = parseInt(props.id);
    httpClient(
      `${process.env.REACT_APP_BASE_URL}/Admin/RenewAccessCode?userId=${userId}`,
      {
        method: 'POST'
      }
    )
      .then(({ json }) => {
        if (json.responseCode === 200) {
          notify(json.responseMessage, { type: 'success' });
        } else {
          notify(json.responseMessage, { type: 'warning' });
        }
      })
      .catch((error) => {
        notify('An error occurred', { type: 'warning' });
      })
      .finally(() => {
        setSending(false);
      });
  };

  const validate = (values) => {
    if (!values.userOrganizations) return;
    const errors = {};
    if (!values.userOrganizations.includes(values.defaultOrganization)) {
      errors.defaultOrganization = 'Please select default organization';
    }
    return errors;
  };

  const onSuccess = (res) => {
    notify(`User updated successfully.`, { type: 'success' });
    redirect('/Admin/Users');
    refresh();
  };

  if (loadingOrgs || loadingRoles)
    return (
      <Loading loadingSecondary='The page is loading. Please wait a moment...' />
    );
  if (error) return <Error />;

  return (
    <Edit
      title='Edit User'
      {...props}
      undoable={false}
      mutationMode='pessimistic'
      onSuccess={onSuccess}
    >
      <SimpleForm
        className={classes.root}
        validate={validate}
        toolbar={<CustomToolbar />}
      >
        <Box
          display={{ md: 'block', lg: 'flex' }}
          style={{ width: '100%' }}
          mb='4em'
        >
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant='h6' gutterBottom>
              Edit User
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='First Name'
                  source='firstName'
                  validate={requiredValidate}
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='Last Name'
                  source='lastName'
                  validate={requiredValidate}
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='Display Name'
                  source='displayName'
                  validate={requiredValidate}
                  fullWidth
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='Email Address'
                  source='email'
                  validate={requiredValidate}
                  fullWidth
                  disabled
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='Phone #'
                  source='phoneNumber'
                  validate={requiredValidate}
                  fullWidth
                  format={formatNumber}
                  helperText={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextInput
                  label='Patient ID'
                  source='patientId'
                  fullWidth
                  helperText={false}
                  validate={requiredValidate}
                />
              </Grid>
              <FormDataConsumer>
                {({ formData }) => (
                  <>
                    {formData.userOrganizations && (
                      <Grid item xs={12} md={6}>
                        <SelectArrayInput
                          label='Organizations'
                          source='userOrganizations'
                          choices={organizations}
                          helperText={false}
                          validate={requiredValidate}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData }) => (
                  <>
                    {formData.userOrganizations && (
                      <Grid item xs={12} md={6}>
                        <DefaultOrgInput
                          source='defaultOrganization'
                          organizations={organizations}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData }) => (
                  <>
                    {typeof formData.roles == 'object' && (
                      <Grid item xs={12} md={6}>
                        <SelectArrayInput
                          label='Roles'
                          source='roles'
                          choices={roles}
                          helperText={false}
                          validate={requiredValidate}
                        />
                      </Grid>
                    )}
                  </>
                )}
              </FormDataConsumer>
              <Grid item xs={12} md={6}>
                <TextInput
                  label='Report Email Destination'
                  source='reportEmail'
                  validate={email()}
                  fullWidth
                  helperText={false}
                />
              </Grid>
            </Grid>
            <Box my='2em'>
              <FormDataConsumer>
                {({ formData }) => (
                  <>
                    {formData.status === 'Pending' && (
                      <Button
                        color='primary'
                        onClick={resendAccessCode}
                        disabled={sending}
                        variant='contained'
                      >
                        {sending ? (
                          <CircularProgress size={15} color='secondary' />
                        ) : (
                          <EmailIcon />
                        )}
                        <span className={classes.btnText}>
                          {sending ? 'Sending Code' : 'Resend Code'}
                        </span>
                      </Button>
                    )}
                  </>
                )}
              </FormDataConsumer>
            </Box>
          </Box>
          <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={12}>
                <Card>
                  <CardContent>
                    <ImageInput
                      source='avatar'
                      label='Avatar'
                      accept='image/*'
                      placeholder='Click to upload or change the logo'
                    >
                      <ImageField source='src' title='title' />
                    </ImageInput>
                  </CardContent>
                </Card>
              </Grid>
              {!props.location.search && (
                <Grid item xs={12} md={6} lg={12}>
                  <History />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

const requiredValidate = [required()];

export default UsersEdit;
