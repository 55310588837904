import React from 'react';
import { Link, DateField, useRecordContext } from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Person, AccessTime } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: 5
  }
}));

const History = (props) => {
  const record = useRecordContext();
  const classes = useStyles();
  return (
    <Card>
      <CardContent>
        <Typography variant='h6' gutterBottom>
          History
        </Typography>
        <Box mt='1em'>
          <Box display='flex' justifyContent='space-between'>
            <Box display='flex' alignItems='center'>
              <AccessTime
                fontSize='small'
                color='disabled'
                className={classes.icon}
              />
              <Typography>Last Updated</Typography>
            </Box>
            {record.modifiedDate ? <DateField source='modifiedDate' /> : 'N/A'}
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <Box display='flex' alignItems='center'>
              <Person
                fontSize='small'
                color='disabled'
                className={classes.icon}
              />
              <Typography>Last Updated By</Typography>
            </Box>
            {record.modifiedBy ? (
              <Link to={`/Admin/Users/${record.modifiedBy.id}`}>
                {record.modifiedBy.name}
              </Link>
            ) : (
              'N/A'
            )}
          </Box>
        </Box>
        <Box mt='1em'>
          <Box display='flex' justifyContent='space-between'>
            <Box display='flex' alignItems='center'>
              <AccessTime
                fontSize='small'
                color='disabled'
                className={classes.icon}
              />
              <Typography>Created Date</Typography>
            </Box>
            {record.createdDate ? <DateField source='createdDate' /> : 'N/A'}
          </Box>
          <Box display='flex' justifyContent='space-between'>
            <Box display='flex' alignItems='center'>
              <Person
                fontSize='small'
                color='disabled'
                className={classes.icon}
              />
              <Typography>Created By</Typography>
            </Box>
            {record.createdBy ? (
              <Link to={`/Admin/Users/${record.createdBy.id}`}>
                {record.createdBy.name}
              </Link>
            ) : (
              'N/A'
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default History;
