import React, { useState, useEffect, useContext } from 'react';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { lightTheme } from '../Layout/theme';
import { httpClient } from '../DataProvider';
import { withRouter } from 'react-router-dom';
import UserContext from '../Utils/UserContext';
import SaveIcon from '@mui/icons-material/Save';
import CRUDInput from '../Components/CRUDInput';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Grid, Button, makeStyles } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Loading, Error, useNotify, Authenticated } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 25px'
  },
  btnText: {
    marginLeft: 5
  },
  error: {
    marginTop: 15,
    textAlign: 'center',
    fontSize: 24
  }
}));

const Permissions = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const { user, setUser } = useContext(UserContext);
  const [value, setValue] = React.useState(0);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAllowed, setIsAllowed] = useState(false);
  const [rolesWithPermissions, setRolesWithPermissions] = useState([]);

  useEffect(() => {
    setLoading(true);
    httpClient(`${process.env.REACT_APP_BASE_URL}/Admin/Permissions`)
      .then(({ json }) => {
        setRolesWithPermissions(json.response);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (user.roles && user.roles.length && user.roles[0].id === 1) {
      setIsAllowed(true);
    }
  }, [user]);

  const handleToggle = (roleId, permissionId, value) => {
    let rolesWPermissions = rolesWithPermissions.slice();
    rolesWPermissions.forEach((role) => {
      if (role.roleId === roleId) {
        role.permissions.forEach((permission) => {
          if (permission.permissionId === permissionId) {
            permission.havePermission = !permission.havePermission;
          }
        });
      }
    });
    setRolesWithPermissions(rolesWPermissions);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const savePermissions = async () => {
    setSaving(true);
    let postData = [];
    rolesWithPermissions.forEach((role) => {
      let permissions = [];
      let truePermissions = role.permissions.filter(
        (permission) => permission.havePermission
      );
      truePermissions.forEach((permission) => {
        permissions.push(permission.permissionId);
      });
      postData.push({
        roleId: role.roleId,
        permissions
      });
    });

    httpClient(`${process.env.REACT_APP_BASE_URL}/Admin/Permissions`, {
      method: 'POST',
      body: JSON.stringify(postData)
    })
      .then(({ json }) => {
        if (user.roles.length) {
          json.response.forEach((role) => {
            if (role.roleId === user.roles[0].id) {
              setUser({ ...user, permissions: role.permissions });
            }
          });
        }
        notify(json.responseMessage, { type: 'success' });
      })
      .catch((error) => {
        notify('An error occurred', { type: 'warning' });
      })
      .finally(() => {
        setSaving(false);
      });
  };

  if (!isAllowed)
    return (
      <div className={classes.error}>
        You do not have the permission to access this page.
      </div>
    );

  if (loading)
    return (
      <Loading loadingSecondary='The page is loading. Please wait a moment...' />
    );
  if (error) return <Error />;

  return (
    <Authenticated location={props.location}>
      <Box className={classes.root}>
        {/* <Typography variant='h6' gutterBottom>
          Privileges
        </Typography> */}
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              {rolesWithPermissions.map((role, index) => (
                <Tab label={role.roleDescription} value={index} />
              ))}
            </TabList>
          </Box>
          {rolesWithPermissions.map((role, index) => (
            <TabPanel value={index}>
              <Grid container spacing={2}>
                <CRUDInput
                  id={role.roleId}
                  role={role}
                  handleToggle={handleToggle}
                />
              </Grid>
            </TabPanel>
          ))}
        </TabContext>
        {/* <Typography variant='h6' gutterBottom>
          Privileges
        </Typography>
        <Grid container spacing={2}>
          {rolesWithPermissions.map((role) => (
            <CRUDInput
              id={role.roleId}
              role={role}
              handleToggle={handleToggle}
            />
          ))}
        </Grid> */}
        <Box mt='2em'>
          <Button
            color='primary'
            onClick={savePermissions}
            disabled={saving}
            variant='contained'
          >
            {saving ? (
              <CircularProgress size={15} color='secondary' />
            ) : (
              <SaveIcon />
            )}
            <span className={classes.btnText}>
              {saving ? 'Saving' : 'Save'}
            </span>
          </Button>
        </Box>
      </Box>
    </Authenticated>
  );
};

const PermissionsWithTheme = (props) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <Permissions {...props} />
  </ThemeProvider>
);

export default withRouter(PermissionsWithTheme);
