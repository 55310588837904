import React, { useState, useEffect } from 'react';
import {
  Create,
  required,
  TextInput,
  SimpleForm,
  BooleanInput,
  SelectInput,
  useGetList,
  Loading,
  Error,
  ImageField,
  ImageInput,
  FormDataConsumer,
  useNotify,
  useRefresh,
  useRedirect
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCard-root': {
      maxWidth: '500px'
    },
    '& .MuiFormControlLabel-root': {
      marginTop: 20
    },
    '& .MuiFormControl-root': {
      minWidth: 250
    },
    '& .previews': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        borderRadius: 20
      }
    }
  }
}));

const OrganizationsCreate = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { data, loading, error } = useGetList('Organizations');
  const [parentOrganizations, setParentOrganizations] = useState([]);

  useEffect(() => {
    setParentOrganizations([]);
    let keys = Object.keys(data);
    keys.forEach((key) => {
      if (data[key].isParent) {
        let org = {
          id: data[key].id,
          name: data[key].name,
          description: data[key].description
        };
        setParentOrganizations((state) => [...state, org]);
      }
    });
  }, [data]);

  const onSuccess = (res) => {
    notify(`Organization created successfully.`, { type: 'success' });
    redirect('/Organizations');
    refresh();
  };

  if (loading)
    return (
      <Loading loadingSecondary='The page is loading. Please wait a moment...' />
    );
  if (error) return <Error />;

  return (
    <Create title='Create Organization' {...props} onSuccess={onSuccess}>
      <SimpleForm
        className={classes.root}
        save={props.save}
        validate={validateOrgCreation}
      >
        <Box
          display={{ md: 'block', lg: 'flex' }}
          style={{ width: '100%' }}
          mb='4em'
        >
          <Box flex={2} mr={{ md: 0, lg: '1em' }}>
            <Typography variant='h6' gutterBottom>
              Create Organization
            </Typography>
            <TextInput
              label='Name'
              source='name'
              validate={requiredValidate}
              fullWidth
              helperText={false}
            />
            <TextInput
              label='Description'
              source='description'
              validate={requiredValidate}
              fullWidth
              helperText={false}
            />
            <BooleanInput label='Parent Organization' source='isParent' />
            <FormDataConsumer>
              {({ formData }) =>
                !formData.isParent && (
                  <>
                    <Typography>Parent Organization</Typography>
                    <SelectInput
                      name='parentOrganization'
                      label='Parent Organization'
                      source='parentOrganization'
                      choices={parentOrganizations}
                    />
                  </>
                )
              }
            </FormDataConsumer>
          </Box>
          <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
            <Card>
              <CardContent>
                <ImageInput
                  source='logo'
                  label='Logo'
                  accept='image/*'
                  placeholder='Click to upload or change the logo'
                >
                  <ImageField source='src' title='title' />
                </ImageInput>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};

const requiredValidate = [required()];

const validateOrgCreation = (values) => {
  const errors = {};
  if (!values.isParent && !values.parentOrganization) {
    errors.parentOrganization = 'Parent Organiaztion is required';
  }
  return errors;
};

export default OrganizationsCreate;
