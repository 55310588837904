import React, { useContext } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import { Link } from 'react-admin';
import { lightTheme } from '../Layout/theme';
import UserContext from '../Utils/UserContext';
import { useLocation } from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@material-ui/styles';
import { Field, withTypes } from 'react-final-form';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Notification, useLogin, useNotify } from 'react-admin';
import { createTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: '#813767',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    minWidth: 375,
    marginTop: '6em'
  },
  logo: {
    marginTop: '0.5em',
    width: 150
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    backgroundColor: theme.palette.primary.main
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em'
  },
  actions: {
    padding: '1em'
  },
  oval1: {
    top: 0,
    right: 0,
    width: '25%',
    position: 'absolute'
  },
  oval2: {
    left: 0,
    bottom: 0,
    width: '50%',
    position: 'absolute'
  },
  footer: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  eyeIcon: {
    marginRight: '-8px !important'
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const { Form } = withTypes();

const Login = () => {
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setUser } = useContext(UserContext);

  const handleSubmit = (auth) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : '/')
      .then((response) => {
        const user = JSON.parse(response.userInfo);
        setUser(user);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          {
            type: 'warning',
            messageArgs: {
              _:
                typeof error === 'string'
                  ? error
                  : error && error.message
                  ? error.message
                  : undefined
            }
          }
        );
      });
  };

  const validate = (values) => {
    const errors = {};
    const re = /\S+@\S+\.\S+/;
    if (!re.test(values.email)) {
      errors.email = 'Email is not valid';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }
    return errors;
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <img
                  src='/images/mybeam-logo.png'
                  alt='logo'
                  className={classes.logo}
                />
              </div>
              <Typography variant='h5' align='center'>
                Admin
              </Typography>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    name='email'
                    component={renderInput}
                    label='Email'
                    disabled={loading}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name='password'
                    component={renderInput}
                    label='Password'
                    type={showPassword ? 'text' : 'password'}
                    disabled={loading}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            edge='end'
                            className={classes.eyeIcon}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className={classes.footer}>
                  <Link to='/Forgot-Password'>
                    <span>Forgot Password?</span>
                  </Link>
                  <Link to='/Validate-Code'>
                    <span>Join?</span>
                  </Link>
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant='contained'
                  type='submit'
                  color='primary'
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  Sign In
                </Button>
              </CardActions>
            </Card>
            <Notification />
            <img src='/images/oval1.png' alt='img' className={classes.oval1} />
            <img src='/images/oval2.png' alt='img' className={classes.oval2} />
          </div>
        </form>
      )}
    />
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string
};

const LoginWithTheme = (props) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;
