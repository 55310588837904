import OrganizationsList from './OrganizationsList';
import OrganizationsEdit from './OrganizationsEdit';
import OrganizationsCreate from './OrganizationsCreate';
import OrganizationsIcon from '@mui/icons-material/Domain';

const resource = {
  list: OrganizationsList,
  create: OrganizationsCreate,
  edit: OrganizationsEdit,
  icon: OrganizationsIcon,
  options: { label: 'Organizations' }
};

export default resource;
