import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import { Link } from 'react-admin';
import { lightTheme } from '../Layout/theme';
import { ThemeProvider } from '@material-ui/styles';
import { Field, withTypes } from 'react-final-form';
import { Notification, useNotify, useRedirect } from 'react-admin';
import { createTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: '#813767',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    width: 375,
    marginTop: '6em'
  },
  logo: {
    marginTop: '0.5em',
    width: 150
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em'
  },
  actions: {
    padding: '1em'
  },
  oval1: {
    top: 0,
    right: 0,
    width: '25%',
    position: 'absolute'
  },
  oval2: {
    left: 0,
    bottom: 0,
    width: '50%',
    position: 'absolute'
  },
  footer: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const BASE_URL = process.env.REACT_APP_BASE_URL;

const { Form } = withTypes();

const ValidateCode = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleSubmit = ({ accessCode }) => {
    setLoading(true);
    const request = new Request(
      `${BASE_URL}/Account/ValidateAccessCode?accessCode=${accessCode}`,
      {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' })
      }
    );
    fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ responseCode, responseMessage }) => {
        if (responseCode === 200) {
          notify('Code Validated Successfully', { type: 'success' });
          redirect('/signup');
        } else {
          notify(responseMessage, { type: 'warning' });
        }
      })
      .catch((e) => {
        notify('An error occurred', { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.accessCode) {
      errors.accessCode = 'Access Code is required';
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <img
                  src='/images/mybeam-logo.png'
                  alt='logo'
                  className={classes.logo}
                />
              </div>
              <Typography variant='h5' align='center'>
                Admin
              </Typography>
              <div className={classes.form}>
                <Typography
                  variant='subtitle1'
                  align='center'
                  className={classes.input}
                >
                  Please provide the access code.
                </Typography>
                <div className={classes.input}>
                  <Field
                    name='accessCode'
                    component={renderInput}
                    label='Access Code'
                    disabled={loading}
                  />
                </div>
                <div className={classes.footer}>
                  <Link to={`/Signup`}>
                    <span>Already validated?</span>
                  </Link>
                  <Link to={`/Login`}>
                    <span>Go Back to Login</span>
                  </Link>
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant='contained'
                  type='submit'
                  color='primary'
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  Validate Code
                </Button>
              </CardActions>
            </Card>
            <Notification />
            <img src='/images/oval1.png' alt='img' className={classes.oval1} />
            <img src='/images/oval2.png' alt='img' className={classes.oval2} />
          </div>
        </form>
      )}
    />
  );
};

ValidateCode.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string
};

const ValidateCodeWithTheme = (props) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <ValidateCode {...props} />
  </ThemeProvider>
);

export default ValidateCodeWithTheme;
