import React from 'react';
import { Route } from 'react-router-dom';
import Signup from './Auth/Signup';
import ValidateCode from './Auth/ValidateCode';
import ForgotPassword from './Auth/ForgotPassword';
import Permissions from './Permissions';
import SuperAdminDashboard from './Dashboard/SuperAdmin';
import OrgAdminDashboard from './Dashboard/OrgAdmin';
import UserAdminDashboard from './Dashboard/UserAdmin';

const routes = [
  <Route exact path='/Signup' component={Signup} noLayout />,
  <Route exact path='/Validate-Code' component={ValidateCode} noLayout />,
  <Route exact path='/Forgot-Password' component={ForgotPassword} noLayout />,
  <Route exact path='/Permissions' component={Permissions} />,
  <Route exact path='/Super-Admin-Dashboard' component={SuperAdminDashboard} />,
  <Route exact path='/Org-Admin-Dashboard' component={OrgAdminDashboard} />,
  <Route exact path='/User-Admin-Dashboard' component={UserAdminDashboard} />
];

export default routes;
