export const darkTheme = {
  palette: {
    primary: {
      main: '#90caf9'
    },
    secondary: {
      main: '#FBBA72'
    },
    type: 'dark' // Switching the dark mode on is a single property value change.
  },
  sidebar: {
    width: 250
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        color: '#ffffffb3',
        backgroundColor: '#616161e6'
      }
    },
    MuiButtonBase: {
      root: {
        '&:hover:active::after': {
          // recreate a static ripple color
          // use the currentColor to make it work both for outlined and contained buttons
          // but to dim the background without dimming the text,
          // put another element on top with a limited opacity
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'currentColor',
          opacity: 0.3,
          borderRadius: 'inherit'
        }
      }
    }
  },
  props: {
    MuiButtonBase: {
      // disable ripple for perf reasons
      disableRipple: true
    }
  }
};

export const lightTheme = {
  palette: {
    primary: {
      main: '#813767'
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff'
    },
    background: {
      default: '#fcfcfe'
    },
    type: 'light'
  },
  shape: {
    borderRadius: 10
  },
  sidebar: {
    width: 250
  },
  overrides: {
    RaLayout: {
      content: {
        paddingLeft: 30
      }
    },
    RaAppBar: {
      toolbar: {
        paddingRight: '0.2em'
      }
    },
    RaSidebar: {
      fixed: {
        backgroundColor: '#813767'
      }
    },
    RaListToolbar: {
      toolbar: {
        alignItems: 'flex-end'
      }
    },
    RaMenuItemLink: {
      root: {
        color: '#fff',
        borderLeft: '3px solid #813767'
      },
      active: {
        color: '#fff',
        fontWeight: 'bold',
        borderLeft: '3px solid #fff'
      },
      icon: {
        color: '#fff'
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none'
      },
      root: {
        border: '1px solid #e0e0e3',
        backgroundClip: 'padding-box'
      }
    },
    MuiButton: {
      root: {
        paddingTop: "6px !important"
      },
      contained: {
        backgroundColor: '#fff',
        color: '#4f3cc9',
        boxShadow: 'none'
      }
    },
    MuiButtonBase: {
      root: {
        '&:hover:active::after': {
          content: '""',
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'currentColor',
          opacity: 0.3,
          borderRadius: 'inherit',
          paddingTop: 0
        }
      }
    },
    MuiAppBar: {
      colorSecondary: {
        color: '#813767',
        backgroundColor: '#fff'
      }
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#f5f5f5'
      },
      barColorPrimary: {
        backgroundColor: '#d7d7d7'
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        border: 'none'
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
};
