import React from 'react';
import { TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    textAlign: 'center'
  }
}));

const ParentOrgField = ({ record }) => {
  const classes = useStyles();

  return record.parentOrganization ? (
    <>
      <TextField source='parentOrganization.name' className={classes.root} />
    </>
  ) : (
    'N/A'
  );
};

ParentOrgField.defaultProps = {
  source: 'parentOrganization.name',
  addLabel: true,
  label: 'Parent Org Name'
};

export default ParentOrgField;
