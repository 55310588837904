const BASE_URL = process.env.REACT_APP_BASE_URL;

const AuthProvider = {
  // called when the user attempts to log in
  login: async ({ email, password }) => {
    const request = new Request(`${BASE_URL}/Account/Login`, {
      method: 'POST',
      body: JSON.stringify({ userId: email, password, isAdminLogin: true }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ response }) => {
        localStorage.setItem('token', response.idToken);
        return response;
      })
      .catch((e) => {
        console.log(e);
        throw new Error('Invalid email or password');
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401) {
      localStorage.removeItem('token');
      window.location.reload(true);
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve()
};

export default AuthProvider;
