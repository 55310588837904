import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import { Link } from 'react-admin';
import { lightTheme } from '../Layout/theme';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@material-ui/styles';
import { Field, withTypes } from 'react-final-form';
import Visibility from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Notification, useNotify, useRedirect } from 'react-admin';
import { createTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: '#813767',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    width: 375,
    marginTop: '6em'
  },
  logo: {
    marginTop: '0.5em',
    width: 150
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em'
  },
  actions: {
    padding: '1em'
  },
  oval1: {
    top: 0,
    right: 0,
    width: '25%',
    position: 'absolute'
  },
  oval2: {
    left: 0,
    bottom: 0,
    width: '50%',
    position: 'absolute'
  },
  footer: {
    marginTop: '1em',
    textAlign: 'center',
    '& span': {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },
  eyeIcon: {
    marginRight: '-8px !important'
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const BASE_URL = process.env.REACT_APP_BASE_URL;

const { Form } = withTypes();

const Signup = () => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = ({ email, password }) => {
    setLoading(true);
    const request = new Request(`${BASE_URL}/Account/SignUp`, {
      method: 'POST',
      body: JSON.stringify({ emailId: email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    });
    fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ responseCode, responseMessage }) => {
        if (responseCode === 200) {
          notify('Sign up Successful', {
            type: 'success'
          });
          redirect('/login');
        } else {
          notify(responseMessage, { type: 'warning' });
        }
      })
      .catch((e) => {
        notify('An error occurred', { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validate = (values) => {
    const errors = {};
    const re = /\S+@\S+\.\S+/;
    if (!re.test(values.email)) {
      errors.email = 'Email is not valid';
    }
    if (!values.email) {
      errors.email = 'Email is required';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    }
    if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Password does not match';
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = 'Password Confirmation is required';
    }
    return errors;
  };

  const handleClickShowPassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleClickShowCoonfirmPassword = (event) => {
    event.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <img
                  src='/images/mybeam-logo.png'
                  alt='logo'
                  className={classes.logo}
                />
              </div>
              <Typography variant='h5' align='center'>
                Admin
              </Typography>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    name='email'
                    component={renderInput}
                    label='Email'
                    disabled={loading}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name='password'
                    component={renderInput}
                    label='Password'
                    type={showPassword ? 'text' : 'password'}
                    disabled={loading}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            edge='end'
                            className={classes.eyeIcon}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name='confirmPassword'
                    component={renderInput}
                    label='Confirm Password'
                    type={showConfirmPassword ? 'text' : 'password'}
                    disabled={loading}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowCoonfirmPassword}
                            edge='end'
                            className={classes.eyeIcon}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className={classes.footer}>
                  <Link to={`/Login`}>
                    <span>Go Back to Login</span>
                  </Link>
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant='contained'
                  type='submit'
                  color='primary'
                  disabled={loading}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  Sign Up
                </Button>
              </CardActions>
            </Card>
            <Notification />
            <img src='/images/oval1.png' alt='img' className={classes.oval1} />
            <img src='/images/oval2.png' alt='img' className={classes.oval2} />
          </div>
        </form>
      )}
    />
  );
};

Signup.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string
};

const SignupWithTheme = (props) => (
  <ThemeProvider theme={createTheme(lightTheme)}>
    <Signup {...props} />
  </ThemeProvider>
);

export default SignupWithTheme;
