const checkPermission = (user, permissionName) => {
  let havePermission = false;
  if (user && user.permissions) {
    let permissions = user.permissions.filter(
      (permission) => permission.permissionName === permissionName
    );
    if (permissions.length) {
      permissions.forEach((permission) => {
        if (permission.havePermission) {
          havePermission = true;
        }
      });
    }
  }
  return havePermission;
};

export default checkPermission;
